import { Link } from "react-router-dom";

export default function Footer() {
    const currentYear = new Date().getFullYear();

    return (<footer id="footer">
    <div class="container">
        <div class="row">
            {/* <div class="col-lg-3 col-md-6 mt-3">
                <h5>Learn More</h5>
                <p class="foot_para">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Atque laborum ipsa quas ab aut quaerat
                    consequatur earum sit illum nobis adipisci eveniet </p>
            </div> */}
            <div class="col-lg-9 col-md-6 mt-3">
                <h5>About Broomy</h5>
                <div class="row">
                    <div class="col-md-6 col-6">
                        <ul>
                         <li> <Link to={'/AboutUs'}>About Us </Link></li>
                            <li> <Link to={'/ContactUs'}>Contact Us </Link></li>
                            {/* <li> <Link to={'/Accessibility'}> Accessibility </Link> </li> */}
                            <li> <Link to={'/GetApp'}> Get the App </Link></li>
                        </ul>
                    </div>
                    <div class="col-md-6 col-6">
                        <ul>
                         
                            <li> <Link to={'/PrivacyStatement'}> Privacy Statement </Link></li>
                            {/* <li> <Link to={'/Cookies'}> Cookie Policy </Link></li> */}
                            <li> <Link to={'/TermsOfService'}> Terms of Service </Link></li>
                            <li> <Link to={'/Blog'} > Read Our Blog </Link></li>
                        </ul>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 mt-3">
                <h5>Need Help?</h5>
                <ul>
                    <li> <Link to={'/Help'}> Help Center </Link></li>

                </ul>
            </div>

            <div class="row margin-row">
                <div class="col-md-6">
                    <p class="social-para"> &#169; {currentYear} Broomyy. All Rights Reserved.</p>
                </div>
                <div class="col-md-6 mt-2">
                    <ul class="social">
                        <li> <a href="#"> <img src="images/facebook.png" alt=""/> </a> </li>
                        <li> <a href="#"> <img src="images/twitter.png" alt=""/> </a> </li>
                        <li> <a href="#"> <img src="images/insta.png" alt=""/> </a> </li>
                        <li> <a href="#"> <img src="images/Pinterest.png" alt=""/> </a> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>);
}
  