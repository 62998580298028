import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AcceptRejectBooking, GetPending } from '../../../../../Reducer/vendorSlice';
import Swal from 'sweetalert2';
import DataTable from 'react-data-table-component';
import DataTableExtensions from "react-data-table-component-extensions";
import 'react-data-table-component-extensions/dist/index.css';
import { Link } from 'react-router-dom';
import Bookingdetails from '../Bookingdetails';
import ChatPopUp from '../../../ChatPopUp';
// import { fetchAccessToken } from '../../../Data/PaypalToken';
import axios from 'axios';


export default function Pending(props) {
    const dispatch = useDispatch();
    const login = useSelector(state => state.auth.auth);
    // const [req, setRequests] = useState([]);
    const [open, setOpen] = useState(false);
    const [bookingId, setBookingId] = useState("");
    const [user, setUser] = useState({});
    const [chat, setChatbot] = useState(false);
    const [resPOP, setReasonPOp] = useState(false);
    const [det, setdetails] = useState({});
    const [accessToken, setAccessToken] = useState(null);


    const columns = [
        {
            name: 'User Name',
            selector: 'username',
            sortable: true,
        },
        {
            name: 'Service',
            selector: 'name',
            sortable: true,
        },
        {
            name: 'Address',
            selector: 'address',
            sortable: true,
        },
        {
            name: 'Number',
            selector: 'phone',
        },
        {
            name: 'Price',
            selector: 'finalPrice',
            cell: (row) => `$ ${row.finalPrice}`,
        },
        {
            name: 'Booking Date',
            selector: 'booking_date',
        },
        {
            name: 'Action',
            button: true,
            width: "300px",

            style: {
                textAlign: "center",
                borderRadius: "4px",
                padding: "10px",
                fontWeight: "600px",
                color: "#f1f1f1",
                marginRight: "10px",
                // background: "#72bd0a",
                border: "none",

            },
            cell: (record) => {
                return (<><button class="btn fff" onClick={() => accept(record.id, record.service_id, record.booking_date, record.orderID, record.payment_id, 'accept', record.finalPrice)}>Accept</button> <button class="btn fff" onClick={() => accept(record.id, record.service_id, record.booking_date, record.orderID, record.payment_id, 'reject')}
                > Reject</button>
                    <button onClick={() => openChild(record.booking_id)} class="btn fff"
                    > View</button>
                    <button onClick={() => OpenChatBot(record.id, record.username, record.image)} class="btn fff"
                    > Chat</button></>
                )
            }
        },

    ];

    function OpenChatBot(id, name, image) {
        setUser({ username: name, id: id, image: image });
        setChatbot(true);
    }

    const receiveResponseFromChild123 = (response) => {
        setChatbot(response);
    };


    useEffect(() => {

        // getToken();
        fetchAccessToken();
    }, [login.user_id]);


    const fetchAccessToken = async () => {
        try {
            const response = await axios.post(
                'https://api.sandbox.paypal.com/v1/oauth2/token',
                null,
                {
                    params: {
                        grant_type: 'client_credentials'
                    },
                    auth: {
                        username: 'AW-nQQrYnhH6H-fxUVWvgiKaqJ5XgiuHmadr1YJuRqV-LskXXd-_d-vOzslDWYv5h6Kprw05sPmxfJU2',
                        password: 'ELOjGQgcVFBpfqbDPFNLDT6SWPI0ZsSwWn-YuIrX9RgcUlCwtUk4lK06kfM_tPtg9Xu3JW5m8uvkTHTy'
                    }
                }
            );
            setAccessToken(response.data.access_token);
        } catch (error) {
            console.error('Error fetching access token:', error);
        }
    };


    async function capturePayment(orderID, finalPrice) {
        try {
            // Step 1: Authorize the payment
            const authorizeResponse = await axios.post(
                `https://api.sandbox.paypal.com/v2/checkout/orders/${orderID}/authorize`,
                {}, // Empty object as the request body
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );

            console.log('authorizeResponse', authorizeResponse);

            // Extract the authorization ID from the authorize response
            const authorizationID = authorizeResponse.data.purchase_units[0].payments.authorizations[0].id;

            // Step 2: Capture the payment using the authorization ID
            const captureResponse = await axios.post(
                `https://api.sandbox.paypal.com/v2/payments/authorizations/${authorizationID}/capture`,
                {}, // Empty object as the request body
                {
                    headers: {
                        'Authorization': `Bearer ${accessToken}`
                    }
                }
            );

            console.log('Capture Response:', captureResponse.data);
            let TransferDetails = await Transfer(finalPrice);
            return captureResponse.data;
        } catch (error) {
            console.error('Capture Error:', error.response.data);
            throw error;
        }
    }

    async function Transfer(finalPrice) {
        console.log('finalPrice', finalPrice);
        // let amount = 1;
        try {

            // Step 2: Transfer the money to the vendor's PayPal.me link
            const transferResponse = await axios.post(
                'https://api.sandbox.paypal.com/v1/payments/payouts',
                {
                    sender_batch_header: {
                        email_subject: 'Booking accepted by vendor'
                    },
                    items: [
                        {
                            recipient_type: 'PAYPAL_ID',
                            amount: {
                                value: finalPrice.toFixed(2), // Amount to be transferred
                                currency: 'USD', // Currency
                            },
                            receiver: "6UA3Q3UAKSJUJ", // Vendor's PayPal ID (PayPal.me link)
                            note: 'Payment to admin.', // Optional note
                            sender_item_id: 'item_1', // Unique ID for the item
                        },
                    ],
                },
                {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log('Transfer Response:', transferResponse.data);
            return transferResponse.data;
        } catch (error) {
            console.error('Transfer Error:', error.response.data);
            throw error;
        }
    }


    const receiveResponseFromChild = (response) => {
        setOpen(response);
    };




    async function openChild(booking_id) {
        setBookingId(booking_id);
        setOpen(true);
    }


    async function accept(id, sid, date, orderId, payment_id, type,finalPrice) {

        if (type == "accept") {
            // let authorise = await authorizedpayment(orderId);

            let capture = await capturePayment(orderId, finalPrice);
            if (capture && capture.status && capture.status === "COMPLETED") {
                let data = { user_id: login.user_id, uid: id, service_id: sid, date: date, type: type, orderId: orderId, payment_id: payment_id };

                let response = await dispatch(AcceptRejectBooking(data));

                if (response.payload.status === 200) {
                    Swal.fire({
                        title: 'Congratulation',
                        text: response.payload.data.message,
                        icon: 'success'
                    });
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: response.payload.data.message,
                        icon: 'error'
                    });
                }
            }
        } else {
            setdetails({ user_id: login.user_id, uid: id, service_id: sid, date: date, type: type, orderId: orderId, payment_id: payment_id });
            setReasonPOp(true);
        }
    }

    function closePop() {
        setReasonPOp(false);
    }

    function cancelReason(e) {
        setdetails({
            ...det,
            ['reason']: e.target.value
        })

    }

    async function cancelApprove() {
        let response = await dispatch(AcceptRejectBooking(det));

        if (response.payload.status === 200) {
            setReasonPOp(false);
            Swal.fire({
                title: 'Congratulation',
                text: response.payload.data.message,
                icon: 'success'
            });


        } else {
            Swal.fire({
                title: 'Error!',
                text: response.payload.data.message,
                icon: 'error'
            });
        }
    }

    return (
        <div class="tab-pane fade show active" id="pills-pending" role="tabpanel"
            aria-labelledby="pills-pending-tab">


            <div class="active_list_inner">

                {open === false &&

                    <div class="table-responsive">

                        {props && props.data && props.data.length > 0 ? (
                            <DataTableExtensions
                                columns={columns}
                                data={props.data}
                                print={false}
                                export={false}
                            >
                                <DataTable
                                    columns={columns}
                                    data={props.data}
                                    fixedHeader
                                    pagination
                                    highlightOnHover
                                />
                            </DataTableExtensions>
                        ) : (
                            <p>No data available</p>
                        )}



                    </div>

                }

                {open === true &&

                    <div>

                        <Bookingdetails id={bookingId} sendResponseToParent={receiveResponseFromChild} />

                    </div>

                }

                {chat && chat == true &&
                    <div>
                        <ChatPopUp data={user} sendResponseToParent={receiveResponseFromChild123}></ChatPopUp>
                        {/*  */}
                    </div>
                }

                {resPOP == true &&
                    <div class="rate">
                        <h5><i class="bi bi-x-square" onClick={closePop}></i></h5>

                        <label>Add Cancellation Reason</label>
                        <input type="text" name="review" onChange={cancelReason} />

                        {/* value={reason} onChange={cancelReason} */}
                        <a class="btn" onClick={cancelApprove}>Add</a>

                    </div>

                }


            </div>



        </div>
    )

}