export const NavbarData = [
    {
        "id": "1",
        "name": "Profile"
    },
    {
        "id": "2",
        "name": "Inbox"

    },
    {
        "id": "3",
        "name": "Gallery"

    },
    {
        "id": "4",
        "name": "Payment Details"

    },
    {
        "id": "5",
        "name": "Availability"

    },
    {
        "id": "6",
        "name": "Services"

    },
    {
        "id": "7",
        "name": "Booking"
    }, {
        "id": "8",
        "name": "Transations"
    }
];


export const ResidentialCat = [
    {
        "id": "1",
        "name": "House"
    },
    {
        "id": "2",
        "name": "Condo"
    },
    {
        "id": "3",
        "name": "Apartment"
    },
    {
        "id": "4",
        "name": "Studio"
    },
    {
        "id": "5",
        "name": "Duplex"
    }
];
export const RentalCat = [
    {
        "id": "1",
        "name": "Lease - Short Term"

    },
    {
        "id": "2",
        "name": "Lease - Long Term"
    },
    {
        "id": "3",
        "name": "AirBnB"
    },
];

export const CommercialCat = [
    {
        "id": "1",
        "name": "Office Space"

    },
    {
        "id": "2",
        "name": "Commercial Shop"
    },
    {
        "id": "3",
        "name": "Others"
    },
];


export const Kitchen = [
    {
        "id": "1",
        "name": "Scrub cabinet exteriors"
    },
    {
        "id": "2",
        "name": "Clean sinks"
    },
    {
        "id": "3",
        "name": "Clean appliance exteriors"
    },
    {
        "id": "4",
        "name": "Clean countertops / tabletops"
    },
    {
        "id": "5",
        "name": "Vacuum and wipe floors"
    }
];

export const Bathrooms = [
    {
        "id": "1",
        "name": "Clean mirrors"
    },
    {
        "id": "2",
        "name": "Eliminate soap scum"
    },
    {
        "id": "3",
        "name": "Clean sinks"
    },
    {
        "id": "4",
        "name": "Clean shower / tub"
    },
    {
        "id": "5",
        "name": "Wipe counter tops"
    },
    {
        "id": "6",
        "name": "Clean toilets"
    },
    {
        "id": "7",
        "name": "Wipe floors"
    },
];

export const Bedrooms = [
    {
        "id": "1",
        "name": "Dust surfaces"
    },
    {
        "id": "2",
        "name": "Make beds"
    },
    {
        "id": "3",
        "name": "Tidy-up small objects"
    },
    {
        "id": "4",
        "name": "Vacuum and wipe floors"
    }
];

export const CommonAreas = [
    {
        "id": "1",
        "name": "Straighten pillows and small objects"
    },
    {
        "id": "2",
        "name": "Dust surfaces"
    },
    {
        "id": "3",
        "name": "Wipe coffee and side tables"
    },
    {
        "id": "4",
        "name": "Vacuum and wipe floors"
    }
];

export const UserNavbar = [
    {
        "id": "1",
        "name": "Profile"
    },
    {
        "id": "2",
        "name": "Inbox"

    },
    {
        "id": "3",
        "name": "Bookings"

    },
];
export const PriorDays = [
    {
        "id": "1",
        "name": "0 day"
    },
    {
        "id": "2",
        "name": "1 day"
    },
    {
        "id": "3",
        "name": "2 days"
    },
    {
        "id": "4",
        "name": "3 days"
    },
    {
        "id": "5",
        "name": "4 days"
    },
    {
        "id": "6",
        "name": "5 days"
    },
    {
        "id": "7",
        "name": "1 week"
    },
    {
        "id": "8",
        "name": "2 weeks"
    }
]; 