import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import firebaseConfig from '../Firebase_config/Firebase';

export default function ChatPopUp(props) {

    const [data, setData] = useState({});
    const [chat, setChat] = useState(false);
    const [img_url, setUrl] = useState("https://admin.broomyy.com/public/storage/profile_images/");
    const login = useSelector(state => state.auth.auth);
    const [chatMessages, setChatMessages] = useState([]);
    console.log('chatMessages', chatMessages);
    const [msgData, setMesageData] = useState({
        message: "",
        name: props.data.username,
        sender_id: login.user_id,
        receiver_id: props.data.id,
        currDate: new Date().toLocaleDateString(),
        currTime: new Date().toLocaleTimeString(),
        readUnread: 0,
        type: "text",
        side: "right",
        seen: 0,
    });


    // const string = "Hello, world!";

    // console.log('proooo', props);
    useEffect(() => {
        
    console.log('msg data', props.data);
    console.log('login data', login); 
        get_message();
    }, [chatMessages]);

    const sendResponse = () => {
        // Step 4: Invoke the function passed from the parent
        props.sendResponseToParent(chat);
    };

    function handleChangeInput(evt) {
        setMesageData({
            ...msgData,
            [evt.target.name]: evt.target.value
        });

      
    }

    function handleSubmit(e) {
        e.preventDefault();

        if (msgData.message != "") {
            firebaseConfig.database().ref(`Chat/${msgData.sender_id}_${msgData.receiver_id}`).push({
                // msgData
                message: msgData.message,
                name: msgData.name,
                sender_id: login.user_id,
                receiver_id: msgData.receiver_id,
                currDate: msgData.currDate,
                currTime: msgData.currTime,
                readUnread: msgData.readUnread,
                type: msgData.type,
                // side: msgData.side,
                seen: msgData.seen,
            });

        }

        firebaseConfig.database().ref(`Chat/${msgData.receiver_id}_${msgData.sender_id}`).push({
            // msgData
            message: msgData.message,
            name: msgData.name,
            sender_id: login.user_id,
            receiver_id: msgData.receiver_id,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            readUnread: msgData.readUnread,
            type: msgData.type,
            // side: msgData.side,
            seen: msgData.seen,
        });


        firebaseConfig.database().ref(`MessagesList/${msgData.sender_id}/${msgData.receiver_id}`).set({

            user_name: msgData.name,
            userProfileImage: props.data.image,
            // userPhoneNumber: phoneNumber,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: msgData.message,
            status: 0,
            userID: msgData.receiver_id,
            type: msgData.type, 
            count: "0"
        });

        firebaseConfig.database().ref(`MessagesList/${msgData.receiver_id}/${msgData.sender_id}`).set({

            user_name: login.first_name+' '+login.last_name,
            userProfileImage: login.image.split("/")[5],
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: msgData.message,
            status: 0,
            userID: msgData.sender_id,
            type: msgData.type,
            count: "1"
        });

        get_message();
        setMesageData({
            ...msgData,
            ["message"]: ""
        });

    }

    const get_message = () => {
        const db = firebaseConfig.database();
        const chatRef = db.ref(`Chat/${login.user_id}_${props.data.id}`);
        chatRef.on("value", (snapshot) => {
            // console.log('abc ', snapshot.val());
            setChatMessages(snapshot.val());
        });

    }


    return (


        <div class="chatdiv chatmodal right_chat">
            <div class="test test-right">
                <span class="close" onClick={sendResponse} ><i class="fa-solid fa-xmark close "></i></span>
                <h4 class="mainuser">
                    <img src={img_url + props.data.image} alt="img" />{props.data.username}<br />
                    <span class="last_seen"> ....</span>

                </h4>

                <div class="innchat">
                    <div class="dddd">
                        <div class="row" id="chat_room1">

                        </div>
                        <div class="row" id="chat_room">

                            {chatMessages && Object.keys(chatMessages).map((key, i) => {
                                const snapshot = chatMessages[key];
                                let viewImage = '';
                                if (snapshot.type === 'text') {
                                    viewImage = snapshot.message;
                                } else {
                                    const images = snapshot.message;
                                    viewImage = <img style={{ width: '100%' }} src={images} alt="Image" />;
                                }
                                const isLeftSide = snapshot.side === 'left';

                                return (
                                    <div key={i} className={`col-12 ${login.user_id == snapshot.receiver_id  ? 'inleft' : 'inright text-right'} message`}>
                                        <div className="chatin">
                                            <p>{viewImage}</p>
                                            <span className={`${isLeftSide ? 'float-right' : ''}`}>{snapshot.currTime}</span>
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <form class="intype" id="chat_form">
                    {/* <span><i class="fas fa-paperclip"></i></span> */}
                    {/* <input type="file" id="files" name="image" /> */}

                    <input type="text" class="form-control" name="message" id="type" 
                        placeholder="Type your message here" onChange={handleChangeInput} value={msgData.message} />
                    <button onClick={handleSubmit}><i class="fas fa-paper-plane"></i></button>
                </form>

            </div>

        </div>

    )


}