import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { verifyOtp, registerFormSubmit, vendorRegisterFormSubmit, verifyUserEmail } from './api';

export const loginForm = createAsyncThunk('login/loginForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await verifyOtp(formData);
    localStorage.setItem('user', JSON.stringify(response.data.userdata));
    localStorage.setItem('token', response.data.token);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const registerForm = createAsyncThunk('register/registerForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await registerFormSubmit(formData);
    // localStorage.setItem('user', JSON.stringify(response.data.userdata));
    // localStorage.setItem('token', response.data.token);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const vendorRegisterForm = createAsyncThunk('register/vendorForm', async (formData, { rejectWithValue }) => {
  try {
    const response = await vendorRegisterFormSubmit(formData);
    // localStorage.setItem('user', JSON.stringify(response.data.userdata));
    // localStorage.setItem('token', response.data.token);
    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});

export const verifyEmail = createAsyncThunk('verifyEmail/verifyEmail', async (data, { rejectWithValue }) => {
  try {
    const response = await verifyUserEmail(data);

    return response;
  } catch (error) {
    return rejectWithValue(error.response);
  }
});


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    submitting: false,
    error: null,
    success: false,
    auth: {},
    token: "", 
    data : null, 

  },
  reducers: {
    setUser: (state, action) => {
      state.auth = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    clearUser: state => {
      state.auth = null;
      state.success = false;
      localStorage.removeItem('user');
    },
    clearToken: state => {
      state.token = null;
      state.success = false;
      localStorage.removeItem('token');
    },
    clearStatus: state => {
      state.success = false;
      state.error = null;
    }, 
    saveFormLogin: (state, action) => {
      state.loginFields = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loginForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(loginForm.fulfilled, (state, action) => {
        state.submitting = false;
        state.auth = action.payload.data.userdata;
        state.token = action.payload.data.token;
        // state.verified = action.payload.data.verified;
        state.success = true;
        state.error = null;
      })
      .addCase(loginForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(registerForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(registerForm.fulfilled, (state, action) => {
        state.submitting = false;
        // state.auth = action.payload.data.userdata;
        // state.token = action.payload.data.token;
        state.success = true;
        state.error = null;
      })
      .addCase(registerForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
    
       .addCase(vendorRegisterForm.pending, state => {
        state.submitting = true;
        state.error = null;
        state.success = false;
      })
      .addCase(vendorRegisterForm.fulfilled, (state, action) => {
        state.submitting = false;
        // state.auth = action.payload.data.userdata;
        // state.token = action.payload.data.token;
        state.success = true;
        state.error = null;
      })
      .addCase(vendorRegisterForm.rejected, (state, action) => {
        state.submitting = false;
        state.error = action.payload;
        state.success = false;
      })
      .addCase(verifyEmail.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.data = action.payload;
        state.loading = false;
        state.error = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
 
     
  },
});
export const { setUser, setToken, clearStatus, clearUser, clearToken } = authSlice.actions;
export default authSlice.reducer;

