import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { submitForm } from '../../../Reducer/formSlice';
import Swal from 'sweetalert2';
import { registerForm, saveFormLogin } from '../../../Reducer/authSlice';
import { clearStatus } from '../../../Reducer/authSlice';
import Loader from "../Loader";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
const schema = Yup.object().shape({
    firstName: Yup.string().required().min(3).max(10),
    rules: Yup.bool().required()
        .test(
            "Please accept terams and conditions",
            function (value) {
                return value === true
            }
        ),
    lastName: Yup.string().required().min(3).max(10),
    email: Yup.string().required().email(),
    phone: Yup.string().min(10, "Phone number must be 10 digits only").max(10, "Phone number must be 10 digits only").required("Phone number is required"),
    age: Yup.date()
        .max(new Date(), "Date of birth must be in the past")
        .test(
            "is-adult",
            "You must be at least 18 years old",
            function (value) {
                const currentDate = new Date();
                const dob = new Date(value);
                const age = currentDate.getFullYear() - dob.getFullYear();
                return age >= 18;
            }
        )
        .required("Date of birth is required"),
});




function Registration() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [image, setImage] = useState("");
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });
    const submitting = useSelector(state => state.auth.submitting);
    const login = useSelector(state => state.auth.auth);
    const error = useSelector(state => state.auth.error);
    const success = useSelector(state => state.auth.success);
    const loading = useSelector(state => state.auth.submitting);
    const [file, setFile] = useState();
    const [fileData, setData] = useState();
    const [code, setCountryCode] = useState("1");

    const record = useSelector(state => state.auth.loginFields);


    const onError = (errors) => {
        console.log(errors);
    };
    const onSubmit = async (datas) => {

        console.log('data', datas);
        console.log('codeeee', code);

        let formData = new FormData();
        formData.append('first_name', datas.firstName);
        formData.append('last_name', datas.lastName);
        formData.append('email', datas.email);
        formData.append('number', datas.phone);
        formData.append('dob', new Date(datas.age).toISOString().slice(0, 10));
        formData.append('terms_and_Con', datas.rules);
        formData.append('country_code', code);
        formData.append('role', 'user');
        formData.append('postal_code', '');
        formData.append('socialtype', '');
        formData.append('socialid', '');
        formData.append('device_type', 'web');
        formData.append('promoation_status', '');
        formData.append('file', fileData);
        let res = await dispatch(registerForm(formData));
        if (res.payload.data) {
            Swal.fire({
                title: 'Congratulation!',
                text: 'Your account created successfully',
                icon: 'success'
            });
            dispatch(clearStatus());
            setTimeout(() => {

                navigate('/Login');
            }, 2000);
        }

    };

    if (error && error.status) {
        switch (error.status) {
            case 403:
                Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
            default:
                Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
        }
    }

    function handleChange(e) {
        console.log(e.target.files);
        setData(e.target.files[0]);
        setFile(URL.createObjectURL(e.target.files[0]));
    }

    const handlePhoneChange = (value, country) => {
        setCountryCode(country.dialCode);
        console.log('country', country.dialCode);
        console.log('value', value);
    };


    return (
        <section id="registration" style={{ backgroundImage: "url(../images/sidekix-media-_AK42TQRyCw-unsplash.jpg)" }}>
            <div class="container">
                <div class="row d-flex justify-content-center align-items-center">
                    <div class="col-12 col-md-9 col-lg-7 col-xl-6">
                        <div class="card" style={{ borderRadius: "15px;" }}>
                            <div class="card-body">
                                <h2 class="text-center">Create an Account</h2>

                                <div class="imagefile">
                                    <span><i class="fas fa-camera"></i>

                                        <input type='file' onChange={handleChange} />


                                    </span>

                                    {file ? <img class="w-100" id="blah" src={file} /> : <img class="w-100" id="blah" src="images/placeholder.png" alt="your image" />}

                                    {/* {errors.file && <p>{errors.file.message}</p>} */}
                                </div>

                                <form onSubmit={handleSubmit(onSubmit, onError)}>

                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label">First Name</label>
                                        <input type="text" id="exampleFormControlInput1" name="firstName" class="form-control" {...register('firstName')} />
                                        {errors.firstName && <p className={"error__feedback"}>{errors.firstName.message}</p>}
                                    </div>

                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                                        <input type="text" id="exampleFormControlInput1" name="lastName" class="form-control" {...register('lastName')} />
                                        {errors.lastName && <p className={"error__feedback"}>{errors.lastName.message}</p>}
                                    </div>

                                    <div class="flag_input">
                                        <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                        <div className='flag_input_position'>
                                            <PhoneInput
                                                inputClass="form-control"
                                                country={'ca'}
                                                onlyCountries={['in', 'ca']}
                                                onChange={handlePhoneChange}
                                                value={code}
                                            />

                                        </div>
                                        <input type="text" id="exampleFormControlInput1" class="form-control fillll" name='phone' {...register('phone')} placeholder="Enter phone number" />
                                      
                                        {errors.phone && <p className={"error__feedback"}>{errors.phone.message}</p>}
                                     


                                    </div>

                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label">Email Id</label>
                                        <input type="email" id="exampleFormControlInput1" class="form-control" autoComplete="off" name='email'{...register('email')} placeholder="Enter email id" />
                                        {errors.email && <p className={"error__feedback"}>{errors.email.message}</p>}
                                    </div>

                                    <div class="mb-2">
                                        <label for="exampleFormControlInput1" class="form-label">Age</label>

                                        <input type="date" id="exampleFormControlInput1" class="form-control" name='age'  {...register('age')} max="9999-12-31" />
                                        {errors.age && <p className={"error__feedback"}>{errors.age.message}</p>}
                                    </div>

                                    <div class="form-check  mb-2">
                                        <input class="form-check-input me-2" type="checkbox" value=""
                                            id="form2Example3cg" name='rules' autoComplete="off"  {...register('rules')} noValidate />

                                        <label class="form-check-label" for="form2Example3g">
                                            {/* I agree all statements in <Link to={'/TermsOfService'}>Terms of
                                                service</Link> */}
                                            By signing in or signing up, I agree to
                                            Broomyy.com’s  <Link to={'/TermsOfService'} target="_blank">Terms of
                                                service</Link> and <Link to={'/PrivacyStatement'} target="_blank">Privacy Policy</Link>, confirm that I am 18 years of age or older,
                                            and consent to receiving email communication.


                                        </label>
                                        {errors.rules && <p className={"error__feedback"}>{errors.rules.message}</p>}
                                    </div>

                                    <div class="d-flex justify-content-center">
                                        <button type="submit">Register</button>
                                    </div>
                                    <p class="text-center text-muted">Have already an account? <Link to="/Login">Login here</Link></p>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Loader status={loading}></Loader>

        </section>
    );
};

export default Registration;