import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { Modal, Button } from 'react-bootstrap';
import { sendOtpForm } from '../../../Reducer/sendotpSlice';
import { verifyForm } from '../../../Reducer/verifyformSlice';
import OtpInput from 'react-otp-input';
import GoogleLogin from 'react-google-login';
import FacebookLogin from 'react-facebook-login';
import { clearStatus, loginForm, verifyEmail } from '../../../Reducer/authSlice';
const schema = Yup.object().shape({
    number: Yup.string().min(10).max(16).required(),
});

export default function Login() {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    //const { data, loading, error } = useSelector((state) => state.sendotp);
    const { register, handleSubmit, formState: { errors } } = useForm({
        resolver: yupResolver(schema),
    });

    const [isShow, invokeModal] = React.useState(false);
    const [isEmail, verifyEmailModal] = React.useState(false);
    const [phone, setPhone] = React.useState('');
    const [otp, setOtp] = React.useState('');
    const [email, setemail] = React.useState('');
    const [num, setNum] = React.useState('');
    const submitting = useSelector(state => state.auth.submitting);
    const login = useSelector(state => state.auth.auth);
    const error = useSelector(state => state.auth.error);
    const success = useSelector(state => state.auth.success);

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const Id = searchParams.get('id');


    // var move = success && login && login != {} ? login.role : '';

    // console.log('move', move);


    // switch (move) {
    //     case 'provider':
    //         navigate('/Dashboard');
    //         dispatch(clearStatus());
    //         break;
    //     case 'user':
    //         navigate('/UserDashboard');
    //         dispatch(clearStatus());
    //         break;
    //     default:
    //         navigate('/Login');
    //         dispatch(clearStatus());

    // }

    useEffect(() => {

        console.log('Id', Id);
        if (success && login && login != {}) {
            const move = login.role;

            if (move === "user" && Id === null) {
                navigate('/UserDashboard');
                dispatch(clearStatus());
            } else if (move === 'provider') {
                navigate('/Dashboard');
                dispatch(clearStatus());
            } else if (Id !== null && move === "user") {
                navigate(`/profile/${Id}`);
                dispatch(clearStatus());
            } else {
                navigate('/Login');
                dispatch(clearStatus());
            }
        }
    }, [success, login, navigate, dispatch, Id]);



    const initModal = () => {
        return invokeModal(!isShow)
    }

    const onError = (errors) => {
        console.log(errors);
    };

    const gotoverify = async () => {
        let formData = new FormData();
        formData.append('number', phone);
        formData.append('otp', otp);
        dispatch(loginForm(formData));
    }

    const onSubmit = async (datas) => {
        setPhone(datas.number);
        let formData = new FormData();
        formData.append('number', datas.number);
        try {
            const response = await dispatch(sendOtpForm(formData));
            let record = response.payload;
            console.log('record', response);
            if (record !== undefined) {
                if (record.status === "True") {
                    setNum(record.otp);
                    invokeModal(!isShow);
                } else {
                    Swal.fire({
                        title: 'Error!',
                        text: record.message,
                        icon: 'error'
                    });
                }
            } else {
                Swal.fire({
                    title: 'Error!',
                    text: response.error.message,
                    icon: 'error'
                });
            }
        } catch (e) {
            Swal.fire({
                title: 'Error!',
                text: e.message,
                icon: 'error'
            });
        }
    };

    if (error && error.status) {

        console.log('erorrr', error);
        console.log('erorrr verified', error.data.verified);

        let alertPromise = null;

        switch (error.status) {
            case 403:
                alertPromise = Swal.fire({
                    title: 'Error!',
                    text: error.data.message,
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;


            default:
                alertPromise = Swal.fire({
                    title: 'Error!',
                    text: 'Something went wrong',
                    icon: 'error'
                });
                dispatch(clearStatus());
                break;
        }

        alertPromise.then((result) => {
            // Check if the condition is met
            if (error.data.verified === false) {
                // Open the modal if the condition is false
                verifyUser();
            }
        });
    }


    // if(success && success.status){

    // }

    function verifyUser() {
        console.log('under verigy function');
        invokeModal(!isShow);
        verifyEmailModal(!isEmail);
    }

    function setLink(e) {
        console.log('event', e.target.value);
        setemail(e.target.value);
    }

    async function SendLink() {
        let data = { email: email };
        let send = await dispatch(verifyEmail(data));
        if (send.payload.data.status == "True") {
            Swal.fire({
                title: 'Success!',
                text: send.payload.data.message,
                icon: 'success'
            });
        } else {
            Swal.fire({
                title: 'Error!',
                text: send.payload.data.message,
                icon: 'error'
            });
        }
    }

    const responseGoogle = (response) => {
        console.log(response);

        // Handle the Google login response, e.g., send it to your server
    };

    const responseFacebook = (response) => {
        console.log(response);

        // Handle the Facebook login response, e.g., send it to your server
    };

    function closeModal() {
        verifyEmailModal(false);
    }

    return (
        <section id="login">
            <div class="container">
                <form onSubmit={handleSubmit(onSubmit, onError)}>
                    <div class="row login_width">
                        <div class="col-lg-6">
                            <div class="login_form">
                                <h1>Login</h1>
                                <p>See your growth and get consulting support!</p>
                                <div class="continue">
                                    {/* <a class="btn social_btn" > <span> <img src="images/apple-logo.png" alt="" /> </span>
                                        <span>Continue with Apple</span> </a> */}

                                    <a class="btn social_btn" > <span> <img src="images/facebook-2.png" alt="" /> </span>
                                        <span>Continue with Facebook</span> </a>

                                    <a class="btn social_btn" > <span> <img src="images/google.png" alt="" /> </span>
                                        <span>Sign in with google</span> </a>
                                    {/* 
                                    <GoogleLogin
                                        clientId="
                                        369007482216-e3eobqko03ufpi8nklj5c38uvdge8ird.apps.googleusercontent.com"
                                        // buttonText="Login with Google"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                        cookiePolicy={'single_host_origin'}
                                    /> */}
                                    {/* <GoogleLogin
                                        clientId="369007482216-e3eobqko03ufpi8nklj5c38uvdge8ird.apps.googleusercontent.com"
                                        onSuccess={responseGoogle}
                                        onFailure={responseGoogle}
                                    />


                                    <FacebookLogin
                                        appId="7102445126479568"
                                        autoLoad={false}
                                        fields="name,email,picture"
                                        callback={responseFacebook}
                                    /> */}

                                </div>
                                <div class="sign-in-seperator">
                                    <span>or Sign in with Phone</span>
                                </div>

                                <div class="mb-2">
                                    <label for="exampleFormControlInput1" class="form-label">Phone Number</label>
                                    <input type="tel" class="form-control" id="exampleFormControlInput1" {...register('number')} placeholder="Enter Phone Number" />
                                    {errors.number && <p>{errors.number.message}</p>}
                                </div>
                                {/* <div class="form-check">
                                    <input class="form-check-input" type="checkbox" />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        Remember me
                                    </label> <a class="float-end" href="#" data-bs-toggle="modal"
                                        data-bs-target="#exampleModal">Forgot Password?</a>
                                </div> */}
                                <button type="submit">Login</button>
                                <p>Not registered yet? <NavLink style={({ isActive }) => ({ color: isActive ? 'greenyellow' : '' })} to="/Registration">Create an account ?</NavLink> </p>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <img class="w-100 login_un_img" src="images/undraw_Login.png" alt="" />
                        </div>
                    </div>
                </form>
                <>
                    <Modal show={isShow}>
                        <Modal.Header closeButton onClick={initModal}>
                        </Modal.Header>
                        <Modal.Body>
                            <section id="otp-form" class="otpmadal">
                                <div class="container">
                                    <div class="row justify-content-md-center">
                                        <div class="col-md-12 text-center">
                                            <div class="row">
                                                <div class="col-sm-12  bgWhite">
                                                    <div class="title">
                                                        <h3>Verify OTP</h3>
                                                        <p>Code has been sent on your phone number</p>
                                                        <p>{num}</p>
                                                    </div>
                                                    <form action="" class="mt-4">
                                                        <OtpInput
                                                            value={otp}
                                                            onChange={setOtp}
                                                            numInputs={4}
                                                            renderSeparator={<span>-</span>}
                                                            renderInput={(props) => <input {...props} className="otp" />}
                                                        />
                                                    </form>
                                                    <hr class="mt-4" />
                                                    <button class="btn btn-primary btn-block mb-4 customBtn" onClick={gotoverify}>Verify</button>
                                                    <p>Didn’t recieve code? <a href="#"> Request again</a></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Modal.Body>
                    </Modal>

                    <Modal show={isEmail} >
                        <Modal.Header closeButton onClick={closeModal}>
                        </Modal.Header>
                        <Modal.Body>
                            <section id="email-form" class="otpmadal Verify">
                                <div class="container">
                                    <div class="row justify-content-md-center">
                                        <div class="col-md-12 text-center">
                                            <div class="row">
                                                <div class="col-sm-12  bgWhite">
                                                    <div class="title">
                                                        <h3>Verify Email</h3>
                                                        {/* <p>Link number</p> */}
                                                        {/* <p>{num}</p> */}
                                                    </div>
                                                    <form action="" class="mt-4">
                                                        <input
                                                            type="email"
                                                            value={email}
                                                            onChange={setLink}
                                                        // numInputs={4}
                                                        // renderSeparator={<span>-</span>}
                                                        // renderInput={(props) => <input {...props} className="otp" />}
                                                        />
                                                    </form>
                                                    <hr class="mt-4" />
                                                    <button class="btn btn-primary btn-block mb-4 customBtn" onClick={SendLink}>Send Link</button>
                                                    {/* <p>Didn’t recieve code? <a href="#"> Request again</a></p> */}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>
                        </Modal.Body>
                    </Modal>
                </>
            </div>
        </section>);
}
