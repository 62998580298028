import firebase from 'firebase';
const firebaseConfig = {
    apiKey: "AIzaSyCOh3E-fXKFda1PcGNMkGzVpzO8ZIg7VvY",
    authDomain: "broomyy-bb80e.firebaseapp.com",
    databaseURL: "https://broomyy-bb80e-default-rtdb.firebaseio.com",
    projectId: "broomyy-bb80e",
    storageBucket: "broomyy-bb80e.appspot.com",
    messagingSenderId: "958925589287",
    appId: "1:958925589287:web:7ac74a90e4d1db98feb702",
    measurementId: "G-JE8ES2XLJT"
  };

firebase.initializeApp(firebaseConfig);

export default firebase;
