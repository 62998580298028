import React, { Component, useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import firebaseConfig from '../Firebase_config/Firebase';

export default function Chat() {
    const [chatMessages, setChatMessages] = useState([]);
    const [messagList, setMessageList] = useState([]);
    const [originalMessagList, setOriginalMessagList] = useState([]);
    const login = useSelector(state => state.auth.auth);
    console.log('login user data', login);
    const [img_url, setUrl] = useState("https://admin.broomyy.com/public/storage/profile_images/");

    console.log('messagelist', chatMessages);
    // const [name, setName] = useState("");
    const [img, SetImage] = useState("");
    // const [rec_id, setReceiverId] = useState("");
    const [msgData, setMesageData] = useState({
        message: "",
        name: "",
        sender_id: login.user_id,
        receiver_id: 0,
        currDate: new Date().toLocaleDateString(),
        currTime: new Date().toLocaleTimeString(),
        readUnread: 0,
        type: "text",
        side: "right",
        seen: 0,
    });




    useEffect(() => {
        get_messageList();
        // get_messageList();

    }, []);



    const get_messageList = e => {
        const dbRef = firebaseConfig.database().ref(`MessagesList/${login.user_id}`);

        dbRef.orderByValue().on("value", snap => {
            const chatItems = [];
            snap.forEach(childSnap => {
                const response = childSnap;
                const dd = response.val().lastmessage.substring(0, 10);
                const activeIcon = response.val().status === 1 ? '<i class="fas fa-circle"></i>' : '';
                const mcount = response.val().count === 0 ? '' : response.val().count;

                chatItems.push({
                    user_id: response.key,
                    name: response.val().user_name,
                    profileImage: response.val().userProfileImage,
                    messageCount: mcount,
                    lastMsg: dd
                }

                );
            });
            setMessageList(chatItems);
            setOriginalMessagList(chatItems);
        });

    }

    function openMsg(userId, img, name) {

        // setName(name);
        SetImage(img);
        setMesageData({
            ...msgData,
            ["name"]: name,
            ["receiver_id"]: userId
        });

        get_message(userId);


    }

    function get_message(userId) {
        const db = firebaseConfig.database();
        const chatRef = db.ref(`Chat/${login.user_id}_${userId}`);
        chatRef.on("value", (snapshot) => {
            // console.log('abc ', snapshot.val());
            setChatMessages(snapshot.val());
        });
    }

    function handleSubmit(e) {
        e.preventDefault();

        console.log('form data', msgData);

        if (msgData.message != "") {
            firebaseConfig.database().ref(`Chat/${msgData.sender_id}_${msgData.receiver_id}`).push({
                // msgData
                message: msgData.message,
                name: msgData.name,
                sender_id: login.user_id,
                receiver_id: msgData.receiver_id,
                currDate: msgData.currDate,
                currTime: msgData.currTime,
                readUnread: msgData.readUnread,
                type: msgData.type,
                // side: msgData.side,
                seen: msgData.seen,
            });

        }

        firebaseConfig.database().ref(`Chat/${msgData.receiver_id}_${msgData.sender_id}`).push({
            // msgData
            message: msgData.message,
            name: msgData.name,
            sender_id: login.user_id,
            receiver_id: msgData.receiver_id,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            readUnread: msgData.readUnread,
            type: msgData.type,
            // side: msgData.side,
            seen: msgData.seen,
        });


        firebaseConfig.database().ref(`MessagesList/${msgData.sender_id}/${msgData.receiver_id}`).set({

            user_name: msgData.name,
            userProfileImage: img,
            // userPhoneNumber: phoneNumber,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: msgData.message,
            status: 0,
            userID: msgData.receiver_id,
            type: msgData.type,
            count: "0"
        });

        firebaseConfig.database().ref(`MessagesList/${msgData.receiver_id}/${msgData.sender_id}`).set({

            user_name: login.first_name + ' ' + login.last_name,
            userProfileImage: login.image.split("/")[5],
            // userPhoneNumber: phoneNumber,
            currDate: msgData.currDate,
            currTime: msgData.currTime,
            lastmessage: msgData.message,
            status: 0,
            userID: msgData.sender_id,
            type: msgData.type,
            count: "1"
        });

        get_message(msgData.receiver_id);
    }

    function handleChangeInput(evt) {
        setMesageData({
            ...msgData,
            [evt.target.name]: evt.target.value
        });

    }


    function searchUser(evt) {
        const searchValue = evt.target.value.toLowerCase();
        if (searchValue) {
            // Filter the original array and store the filtered results.
            const filtered = originalMessagList.filter(item =>
                item.name.toLowerCase().includes(searchValue)
            );
            setMessageList(filtered);
        } else {
            // If the search input is cleared, reset messagList to the original array.
            setMessageList(originalMessagList);
        }

    }

    return (
        <>
            <div class="tab-pane fade show active" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

                <section class="message-area">
                    <div class="container">

                        <div class="chat-area">

                            <div class="chatlist">
                                <div class="modal-dialog-scrollable">
                                    <div class="modal-content">
                                        <div class="chat-header">
                                            <div class="msg-search">
                                                <input type="text" class="form-control" id="inlineFormInputGroup" onChange={searchUser}
                                                    placeholder="Search" aria-label="search" />

                                                <a class="btn add"><i class="bi bi-search"></i></a>
                                            </div>

                                        </div>

                                        <div class="modal-body">

                                            <div class="chat-lists">
                                                <div class="tab-content" id="myTabContent">
                                                    <div class="tab-pane fade show active" id="Open" role="tabpanel"
                                                        aria-labelledby="Open-tab">


                                                        <div class="chat-list">

                                                            {messagList && messagList.map((item, i) => (
                                                                <a onClick={() => openMsg(item.user_id, item.profileImage, item.name)} class="d-flex align-items-center">
                                                                    <div class="flex-shrink-0">
                                                                        <img class="img-fluid"
                                                                            src={img_url + item.profileImage}
                                                                            alt="user img" />
                                                                        <span class="active"></span>
                                                                    </div>
                                                                    <div class="flex-grow-1 ms-3">
                                                                        <h3>{item.name}</h3>
                                                                        <p>{item.lastMsg}</p>
                                                                    </div>
                                                                </a>
                                                            ))}

                                                            {messagList.length === 0 &&
                                                                <p>No messages yet!</p>
                                                            }


                                                        </div>


                                                    </div>


                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                            {chatMessages && Object.keys(chatMessages).length > 0 &&

                                <div class="chatbox">
                                    <div class="modal-dialog-scrollable">
                                        <div class="modal-content">
                                            <div class="msg-head">
                                                <div class="row">
                                                    <div class="col-8">
                                                        <div class="d-flex align-items-center">
                                                            <span class="chat-icon"><img class="img-fluid"
                                                                src="https://mehedihtml.com/chatbox/assets/img/arroleftt.svg"
                                                                alt="image title" /></span>
                                                            <div class="flex-shrink-0">
                                                                <img class="img-fluid w-100"
                                                                    src={img_url + img} />
                                                            </div>
                                                            <div class="flex-grow-1 ms-3">
                                                                <h3>{msgData.name}</h3>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="modal-body">
                                                <div class="msg-body">
                                                    <ul>


                                                        {chatMessages && Object.keys(chatMessages).map((key, i) => {
                                                            const snapshot = chatMessages[key];
                                                            let viewImage = '';
                                                            if (snapshot.type === 'text') {
                                                                viewImage = snapshot.message;
                                                            } else {
                                                                const images = snapshot.message;
                                                                viewImage = <img style={{ width: '100%' }} src={images} alt="Image" />;
                                                            }
                                                            const isLeftSide = snapshot.side === 'left';
                                                            return (
                                                                <li key={i} className={`col-12 ${login.user_id == snapshot.receiver_id ? 'sender text-right' : 'repaly'} message`}>
                                                                    <div className="chatin">
                                                                        <p>{viewImage}</p>

                                                                        <span className={`${isLeftSide ? 'float-right' : ''}`}>

                                                                            {snapshot.currTime}</span>
                                                                    </div>
                                                                </li>
                                                            );
                                                        })}
                                                    </ul>
                                                </div>
                                            </div>

                                            <div class="send-box">
                                                <form action="">
                                                    {/* <span><i class="fas fa-paperclip"></i></span>
                                                    <input type="file" id="files" name="image" /> */}
                                                    <input type="text" class="form-control" aria-label="message…" name="message"
                                                        placeholder="Write message…" onChange={handleChangeInput} />

                                                    <button type="button" onClick={handleSubmit}><i class="fa fa-paper-plane"
                                                        aria-hidden="true"></i> Send</button>
                                                </form>



                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }

                            {chatMessages.length === 0 &&

                                <div class="chatbox heading"><h1>Welcome, {login.first_name}</h1><p>Ready? Set. Chat! Let's jump right into things.</p></div>

                            }

                        </div>





                    </div>

                </section>




            </div>

        </>
    )

}